<template>
    <div class="mod-list-wrapper">
        <h2>All WhatsApp Mods</h2>
        <div class="mod-list">
            <div class="mod-item" v-for="(item, i) in modList" :key="i">
                <img :src="item.img" :alt="item.alt" class="mod-img">
                <div class="mod-info">
                    <div class="mod-title">
                        <router-link class="nocolor" :to="makeURL(item.url)" v-if="item.url">{{ item.title }}</router-link>
                        <div v-else>
                            {{ item.title }}
                        </div>
                    </div>
                    <p v-if="item.version">{{ item.version }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modList: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            default: 'en'
        }
    },
    computed: {
        makeURL() {
            if (this.language === 'en') {
                return (param) => `${param}`;
            } else {
                return (param) => `/${this.language}${param}`;
            }
        }
    },
}
</script>